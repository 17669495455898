import { Box, Button, List, ListDivider, ListItem, ListItemContent, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../utils/dateFormater';
import { maskCreditCardNumber } from '../../../utils/formatCreditCard';
import { NotificationLog } from '../../../utils/types';
import NotLogItemModal from '../NotLogItemModal';

const NotLogItem = ({ listItem }: { listItem: NotificationLog }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(inOpen);
  };

  return (
    <List
      size="sm"
      sx={{
        position: 'relative',
        '--ListItem-paddingX': 0,
      }}
    >
      <ListItem
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          position: 'relative',
        }}
      >
        <ListItemContent>
          <Box sx={{ mt: 2 }}>
            <Typography level="body-xs">{t('history_table_card')}</Typography>
            <Typography level="body-xs">{maskCreditCardNumber(listItem.card)}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('header')}</Typography>
            <Typography level="body-md">{listItem.header}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('card_search_bank')}</Typography>
            <Typography level="body-md">{listItem.app}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('matched')}</Typography>
            <Typography level="body-md">{listItem.matched ? '+' : '-'}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('received_at')}</Typography>
            <Typography level="body-md">{formatDateTime(listItem.received_at.toString())}</Typography>
          </Box>
          <Button
            variant="plain"
            sx={{
              display: 'block',
              position: 'absolute',
              top: 0,
              right: '3px',
              borderBottom: '1px solid #947EFE',
              color: '#947EFE',
              borderRadius: 0,
              p: 0,
            }}
            onClick={toggleDrawer(true)}
          >
            {t('history_table_details')}
          </Button>
        </ListItemContent>
      </ListItem>
      <ListDivider />
      <NotLogItemModal
        id={listItem.id}
        open={open}
        onClose={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      />
    </List>
  );
};

export default NotLogItem;
