import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Grid } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import DonutChart from './DonutChart';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import { getConstants, withdrawalBalance } from '../../services/operators';
import {
    getOperatorById,
    resetFrozenBalance,
    updateFrozenBalance,
} from '../../redux/auth/operations';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import useWidth from '../../hooks/useWidth';
import IconComponent from '../IconComponent/IconComponent';
import { boxStyle, triangleStyle } from './styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ModalMyWallet from './ModalMyWallet';
import Withdrawals from '../../pages/Withdrawals';
import { DepositTransactionsTable } from '../../pages/TopUpDeposit/DepositTransactionsTable';
import { selectTransactions } from '../../redux/operatorBalanceAndTransactions/selectors';
import ModalWithdrawal from './ModalWithdrawal';
import ModalTopUp from './ModalTopUp';
import { socket } from '../../socket';
import BalanceBox from './BalanceBox';
import { copy, qr } from './data';
import TextComponent from './TextComponent';
import { useChartData } from './chartData';
import { updateBalance, updatebannedFrozenLimit, updateFrozenLimit } from '../../redux/auth/actions';
import { getBalanceAndTransactionsThunk } from '../../redux/operatorBalanceAndTransactions/thunks';
import QrModal from './QrModal';

const Wallet = () => {
    const { user } = useSelector(getGlobalUser);
    const [isCopied, setIsCopied] = useState(false);
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [openWithdrawal, setOpenWithdrawal] = useState(false);
    const [validation, setValidation] = useState(false);
    const [amountWithdrawal, setAmountWithdrawal] = useState('');
    const dispatch = useDispatch<AppDispatch>();
    const [myWalletModal, setMyWalletModal] = useState(false);
    const [myWalletModalError, setMyWalletModalError] = useState(false);
    const { isMobile, isDesktop } = useWidth();
    const [amount, setAmount] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [disabledWithdrawal, setDisabledWithdrawal] = useState(true);
    const [showWithdrawal, setShowWithdrawal] = useState(true);
    const [showDeposit, setShowDeposit] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [isValidTopUp, setIsValidTopUp] = useState(false);
    const [isInvalidTopUp, setIsInvalidTopUp] = useState(false);
    const { seriesActive, seriesInactive, seriesFrozen, optionsActive, optionsInactive, optionsFrozen } = useChartData();
    const transactions = useSelector(selectTransactions);
    const [minAmountWithdrawal, setMinAmountWithdrawal] = useState('');
    const [minAmounTopup, setMinAmountTopup] = useState('');
    const [showQrCode, setShowQrCode] = useState(false);

    useEffect(() => {
        dispatch(getOperatorById(user.id));
        dispatch(getBalanceAndTransactionsThunk());
        socket.on('operatorBalance', data => {
            dispatch(updateBalance(data.deposit));
            dispatch(updateFrozenLimit(data.frozen_limit));
            dispatch(updatebannedFrozenLimit(data.banned_frozen_limit));
        });
        return () => {
            socket.off('operatorBalance');
        };
    }, []);

    const onCopyText = () => {
        setIsCopied(true);

        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };

    const toggleOpenModal = () => {
        setOpenModal(!openModal);
    };

    const toggleOpenWithdrawal = () => {
        setOpenWithdrawal(!openWithdrawal);
    };
    const handleChange = (value: string) => {
        setAmount(value);
        const isValidValue = value.length >= 2 && parseInt(value) >= Number(minAmounTopup) && parseInt(value) <= user.deposit;
        const isInvalidValue =
            value.length < 2 ||
            (value.length === 2 && parseInt(value) < Number(minAmounTopup)) ||
            parseInt(value) > user.deposit;
        setIsValidTopUp(isValidValue);
        setIsInvalidTopUp(isInvalidValue);
        if (parseInt(value) >= Number(minAmounTopup) && parseInt(value) <= user.deposit) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const onSubmit = () => {
        user?.id && dispatch(updateFrozenBalance({ amount: Number(amount), id: user.id }));
        toggleOpenModal();
        setAmount('');
        setMyWalletModal(true);
    };

    const handleRemoveFrozenBalance = () => {
        user?.id && dispatch(resetFrozenBalance({ id: user.id }));
        toggleOpenModal();
        setAmount('');
    };
    useEffect(() => {
        const fetch = async () => {
            const resp = await getConstants();
            if (resp) {
            setMinAmountWithdrawal(resp.MIN_WITHDRAWAL_AMOUNT);
            setMinAmountTopup(resp.MIN_TRADING_BALANCE_TOP_UP) 
            }
         
        }
        fetch();
    }, [])

    const handleWithdrawal = async () => {
        if (!user?.id) {
            return;
        }
        if (Number(amountWithdrawal) < Number(minAmountWithdrawal) || Number(amountWithdrawal) > Number(user.deposit)) {
            setValidation(true);
            return;
        } else {
            setValidation(false);
            const withdraw = await withdrawalBalance(Number(amountWithdrawal));

            if (withdraw) {
                dispatch(getOperatorById(user.id));
                setMyWalletModal(true);
                setDisabled(true);
                setOpenWithdrawal(false);
                setAmountWithdrawal('');
            } else {
                setMyWalletModalError(true);
            }
        }
    };

    const handleChangeWithdrawal = (value: string) => {
        setAmountWithdrawal(value);
        setDisabledWithdrawal(false);
        const isValidValue = value.length >= 2 && parseInt(value) >= Number(minAmountWithdrawal);
        const isInvalidValue = value.length < 2 || (value.length >= 2 && parseInt(value) < Number(minAmountWithdrawal));
        setIsValid(isValidValue);
        setIsInvalid(isInvalidValue);
    };

    const handleWithdrawalButtonClick = () => {
        setShowWithdrawal(true);
        setShowDeposit(false);
    };

    const handleDepositButtonClick = () => {
        setShowWithdrawal(false);
        setShowDeposit(true);
    };
    const handleRefresh = () => {
        if (showDeposit) {
            dispatch(getBalanceAndTransactionsThunk());
        } else {
            window.location.reload();
        }
    };
    const title = (Number(user?.deposit) + Number(user?.frozen_limit)).toFixed(2);
    const toggleQrCode = () => {
        setShowQrCode(!showQrCode);
    };
    return (
        <>
            <Typography level={isMobile ? "h3" : "h2"} sx={isMobile ? { mt: 2 } : undefined}>{t('myWalletHeader')}</Typography>
            <Grid container spacing={2} sx={{
                marginTop: '40px',

            }}>
                <Grid xs={12} md={8}>

                    <Box
                        sx={{
                            borderRadius: '16px',
                            padding: '24px 36px',
                            background: 'rgba(17, 19, 23, 1)',
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography level="h3">
                                {t('myWalletTitle')}
                            </Typography>
                            <Button variant='outlined' onClick={()=>setShowQrCode(true)} sx={{borderColor:'transparent'}}>
                                <IconComponent viewBox="0 0 32 32" paths={qr} />
                            </Button>
                        </Box>

                        <Box sx={{ display: isMobile ? 'block' : 'flex', alignItems: 'center', marginTop: '40px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', position: 'relative', width: isMobile ? '280px' : '317px' }}>
                                <DonutChart />
                                <Typography sx={{
                                    fontSize: isMobile ? '20px' : '28px',
                                    fontWeight: 600,
                                    color: '#F1F1F1',
                                    textAlign: 'center',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',

                                }}>

                                    {t('deposite', { deposit: title })}

                                </Typography>
                            </Box>

                            <Box>
                                <TextComponent
                                    bgColor="#947EFE"
                                    text={t('tradingBalance')}
                                    info={`(${t('active')})`}
                                />
                                <TextComponent
                                    bgColor="#495057"
                                    text={t('myWalletBalance')}
                                    info={`(${t('inactive')})`}
                                />
                                <TextComponent
                                    bgColor="#65BEFF"
                                    text={t('heldInTransactions')}
                                    info={`(${t('frozen')})`}
                                />
                            </Box>
                        </Box>


                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginTop: '40px',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography>{t('walletId')}:</Typography>
                                <Typography sx={{ ml: 1, overflowWrap: 'anywhere', fontSize: isDesktop ? '16px' : '14px', fontWeight: 600, color: '#F1F1F1' }}>
                                    {user.deposit_wallet}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                <CopyToClipboard text={user.deposit_wallet} onCopy={onCopyText} color="#f1f1f1">
                                    <IconComponent viewBox="0 0 24 24" paths={copy} fill="#f1f1f1" />
                                </CopyToClipboard>

                                <Typography>{t('copyId')}</Typography>
                                {isCopied && (
                                    <Box sx={boxStyle}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" fill="none">
                                            <path
                                                stroke="#18E806"
                                                strokeLinecap="round"
                                                strokeWidth="2"
                                                d="m1 9 3.2 2.4a1 1 0 0 0 1.4-.1L14 1"
                                            />
                                        </svg>
                                        <Typography sx={{ marginLeft: '10px' }}>{t('copied')}</Typography>
                                        <Box sx={triangleStyle}></Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>

                        <Box sx={{ marginTop: '38px', display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                sx={{
                                    background:
                                        'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',

                                    width: '100%',
                                    padding: isMobile ? '10px' : '10px 16px',
                                    '&:hover': {
                                        background:
                                            'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                                    },
                                }}
                                onClick={toggleOpenModal}
                            >
                                {t('sidebar_frozen_btn')}
                            </Button>
                            <Button
                                className="btnGradient"
                                sx={{
                                    width: '100%',
                                    padding: '10px 16px',
                                    ml: 3,
                                    '&:hover': {
                                        background:
                                            'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                                        color: '#f1f1f1',
                                    },
                                }}
                                variant="outlined"
                                onClick={toggleOpenWithdrawal}
                            >
                                {t('sidebar_btn_withdrawal')}
                            </Button>
                        </Box>
                    </Box>



                </Grid>


                <Grid xs={12} md={4} spacing={2} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>

                    <BalanceBox
                        balance={t('myWalletBalance')}
                        info={t('inactive')}
                        amount={t('deposite', { deposit: Number(user?.deposit).toFixed(2) })}
                        backgroundColor="#495057"
                        textColor="#CED4DA"
                        bgColor="rgba(73, 80, 87, 0.3)"
                        series={seriesInactive}
                        options={optionsInactive}
                    />

                    <BalanceBox
                        balance={t('tradingBalance')}
                        info={t('active')}
                        amount={t('deposite', { deposit: Number(user?.frozen_limit).toFixed(2) })}
                        backgroundColor="#947EFE"
                        textColor="#947EFE"
                        bgColor="rgba(104, 79, 221, 0.2)"
                        series={seriesActive}
                        options={optionsActive}
                    />

                    <BalanceBox
                        balance={t('heldInTransactions')}
                        info={t('frozen')}
                        amount={t('deposite', { deposit: Number(user?.banned_frozen_limit) < 0 ? 0 : Number(user?.banned_frozen_limit).toFixed(2) })}
                        backgroundColor="#65BEFF"
                        textColor="#65BEFF"
                        bgColor="rgba(101, 190, 255, 0.2)"
                        series={seriesFrozen}
                        options={optionsFrozen}
                    />
                </Grid>

            </Grid>
            <ModalTopUp
                openModal={openModal}
                toggleOpenModal={toggleOpenModal}
                onSubmit={onSubmit}
                amount={amount}
                handleChange={handleChange}
                disabled={disabled}
                handleRemoveFrozenBalance={handleRemoveFrozenBalance}
                isValidTopUp={isValidTopUp}
                isInvalidTopUp={isInvalidTopUp}
                minAmounTopup={minAmounTopup}
            />
            <ModalWithdrawal
                openWithdrawal={openWithdrawal}
                toggleOpenWithdrawal={toggleOpenWithdrawal}
                amountWithdrawal={amountWithdrawal}
                handleChangeWithdrawal={handleChangeWithdrawal}
                handleWithdrawal={handleWithdrawal}
                disabledWithdrawal={disabledWithdrawal}
                validation={validation}
                isValid={isValid}
                isInvalid={isInvalid}
                minAmountWithdrawal={minAmountWithdrawal}
            />
            <ModalMyWallet
                isOpen={myWalletModal}
                onClose={() => setMyWalletModal(!myWalletModal)}
                icon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="40" fill="none">
                        <path
                            stroke="#18E806"
                            strokeLinecap="round"
                            strokeWidth="4"
                            d="m2.7 28.7 10.7 8a3.3 3.3 0 0 0 4.6-.5L46 2"
                        />
                    </svg>
                }
                content={t('requestSuccess')}
            />
            <ModalMyWallet
                isOpen={myWalletModalError}
                onClose={() => setMyWalletModal(!myWalletModalError)}
                icon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="none">
                        <path
                            fill="#EB5757"
                            fill-rule="evenodd"
                            d="m40 44.7 18.9 18.9a3.3 3.3 0 0 0 4.7-4.7L44.7 40l18.9-18.9a3.3 3.3 0 0 0-4.7-4.7L40 35.3 21.1 16.4a3.3 3.3 0 0 0-4.7 4.7L35.3 40 16.4 58.9a3.3 3.3 0 1 0 4.7 4.7L40 44.7Z"
                            clip-rule="evenodd"
                        />
                    </svg>
                }
                content={t('requestError')}
            />
            <Typography level={isMobile ? "h3" : "h2"} sx={{ marginTop: '60px', mb: 3 }}>
                {t('myWalletTransactions')}
            </Typography>
            <Box
                sx={{
                    position: 'relative',
                }}
            >
                <Button
                    onClick={handleWithdrawalButtonClick}
                    sx={{
                        background: showWithdrawal ? '#18191A' : '#0B0C0F',
                        borderRadius: '8px 0 0 0',
                        '&:hover': {
                            background:
                                'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                        },
                    }}
                >
                    {t('history_header_withdrawal')}
                </Button>

                <Button
                    onClick={handleDepositButtonClick}
                    sx={{
                        background: showWithdrawal ? '#18191A' : '#0B0C0F',
                        borderRadius: '0 8px 0 0',
                        '&:hover': {
                            background:
                                'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                        },
                    }}
                >
                    {t('deposit')}
                </Button>

                <Button
                    className="btnGradient"
                    sx={{
                        padding: '10px 17px',
                        position: 'absolute',
                        top:  isMobile ? '-57px' : '-10px' ,
                        right: 0,
                        '&:hover': {
                            background:
                                'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                            color: '#f1f1f1',
                        },
                    }}
                    variant="outlined"
                    onClick={handleRefresh}
                >
                    <IconComponent
                        sx={{ mr: 1 }}
                        paths="m19.3 6.7.4-1.4a1 1 0 0 1 2 .6l-1.2 3.8a1 1 0 0 1-1.3.7l-3.8-1.2a1 1 0 1 1 .6-1.9l1.7.5a7.4 7.4 0 0 0-13 1.3l-.3.6A1 1 0 1 1 2.6 9l.2-.7a9.4 9.4 0 0 1 16.5-1.6ZM3.8 17.3l-.3 1.3a1 1 0 1 1-2-.5l1-3.8a1 1 0 0 1 1.3-.8l3.8 1a1 1 0 0 1-.5 2L5.3 16a7.4 7.4 0 0 0 13.2-1.8 1 1 0 1 1 2 .6 9.4 9.4 0 0 1-16.7 2.5Z"
                    />
                    {t('refreshBtn')}
                </Button>
                {showWithdrawal && <Withdrawals />}
                {showDeposit && <DepositTransactionsTable transactions={transactions} />}
                {showQrCode && <QrModal showQrCode={showQrCode} toggleQrCode={toggleQrCode} />}
            </Box>
        </>
    );
};

export default Wallet;
