/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Suspense, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import GlobalStyles from '@mui/joy/GlobalStyles';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import NotFound from './pages/NotFound';
import PrivateRoute from './components/PrivateRoute';
import SignIn from './pages/SignIn';
import Home from './pages/Home';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import GoogleAuth from './pages/GoogleAuth';
import Orders from './pages/Orders';
import Active from './pages/Active';
import SignInKey from './pages/SignInKey';
import NewPassword from './pages/NewPassword';
import Cards from './pages/Cards';
import History from './pages/History';
import Settings from './pages/Settings';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from './redux/store';
import { getGlobalUser } from './redux/auth/selectors';
import { getOperatorById } from './redux/auth/operations';

import { socket } from './socket';
import TopUpDeposit from './pages/TopUpDeposit';
import Dashboard from './pages/Dashboard';
import Withdrawals from './pages/Withdrawals';
import Terminals from './pages/Terminals';
import TransactionHistoryQAC from './pages/TransactionHistoryQAC';
import MyWallet from './pages/MyWallet';
import HistoryP from './pages/HistoryPayout';
import ActivePayouts from './pages/ActivePayouts';
import ActiveDisputes from './pages/ActiveDisputes';
import NotificationLogs from './pages/NotificationLogs';

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const { user, isLoggedIn, access_token } = useSelector(getGlobalUser);
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    if (isLoggedIn && user?.id) {
      dispatch(getOperatorById(user.id));
    }
  }, []);

  if (isLoggedIn && user?.id) {
    socket.io.opts.extraHeaders = { Authorization: `Bearer ${access_token}` };
    socket.connect();
  }

  console.log(isConnected);
  console.log(socket);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);

    };
  }, []);

  return (
    <CssVarsProvider disableTransitionOnChange defaultMode="dark">
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px',
            '--Cover-width': '40vw',
            '--Form-maxWidth': '700px',
            '--Transition-duration': '0.4s',
          },
        }}
      />
      <ToastContainer autoClose={3000} />
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<Home />}>
                <Route path="/" index element={<Navigate replace to="dashboard-operator" />} />
                <Route path="active-transactions" element={<Active />} />
                <Route path="active-payouts" element={<ActivePayouts />} />
                <Route path="active-disputes" element={<ActiveDisputes />} />
                <Route path="notification-logs" element={<NotificationLogs />} />
                <Route path="cards" element={<Cards />} />
                <Route path="history-transactions" element={<History />} />
                <Route path="history-payout" element={<HistoryP />} />
                <Route path="withdraw-history" element={<Withdrawals />} />
                <Route path="top-up-deposit" element={<TopUpDeposit />} />
                <Route path="settings" element={<Settings />} />
                <Route path="dashboard-operator" element={<Dashboard />} />
                <Route path="transaction-history-qac" element={<TransactionHistoryQAC />} />
                <Route path="terminals" element={<Terminals />} />
                <Route path="myWallet" element={<MyWallet />} />
              </Route>
            </Route>
            <Route path="login" element={<SignIn />} />
            <Route path="login-key" element={<SignInKey />} />
            <Route path="confirm-login" element={<GoogleAuth />} />
            <Route path="registration" element={<SignUp />} />
            <Route path="forgot-password/" element={<ForgotPassword />} />
            <Route path="reset-password/:token" element={<NewPassword />} />
            <Route path="transaction" element={<Orders />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </CssVarsProvider>
  );
}

export default App;
