import { Box, Button, Table, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { Transactions } from '../../utils/types';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../utils/dateFormater';
import { getStatusTextColor } from '../../utils/getStatusColor';
import HistoryModalQAC from './HistoryModalQAC';
const QacTransactionById = ({ transactionById }: { transactionById: Transactions }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(inOpen);
  };
  const formatAmountNumber = (number: string): string => {
    const formattedNumber = number.replace(',', '.'); // Ensure correct decimal separator
    const [integerPart, decimalPart] = formattedNumber.split('.') as [string, string | undefined];
    const spacedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return decimalPart !== undefined ? `${spacedIntegerPart}.${decimalPart}` : spacedIntegerPart;
  };
  return (
    <>
      <Table
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',
        }}
      >
        <thead>
          <tr>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_name')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('historyTableTerminal')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_priceUah', {
              currency: (localStorage.getItem('base_currency')) || '',
            })}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_priceUsdt')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('tablePayerBank')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_startDate')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_endDate')}</th>
            <th style={{ width: '12%', color: '#F1F1F1' }}>{t('history_table_status')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_actions')}</th>
          </tr>
        </thead>
        <tbody>
          <tr key={transactionById.id}>
            <td>
              <Typography level="body-xs">
                {(transactionById.label && transactionById.label) || t('not_found')}
              </Typography>
            </td>
            <td>
              <Typography level="body-xs" sx={{ overflowWrap: 'anywhere' }}>
                {transactionById?.jar_link}
              </Typography>
              <Typography level="title-sm">{transactionById.operator_bank_title}</Typography>
            </td>
            <td>
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Typography level="body-md" sx={{ fontWeight: 600, overflowWrap: 'anywhere' }}>
                  {formatAmountNumber(Number(transactionById.amount).toFixed(2).toString())}
                </Typography>
              </Box>
            </td>
            <td>
              <Typography level="body-md" sx={{ overflowWrap: 'anywhere' }}>
                {formatAmountNumber(Number(transactionById.operatorAmount).toFixed(2).toString())}
              </Typography>
            </td>
            <td>
              <Typography level="body-md" sx={{ overflowWrap: 'anywhere' }}>
                {(transactionById.merchant_bank_title && transactionById.merchant_bank_title) ||
                  t('not_found')}
              </Typography>
            </td>
            <td>
              <Typography level="body-md" sx={{ overflowWrap: 'anywhere' }}>
                {formatDateTime(transactionById.createdAt)}
              </Typography>
            </td>
            <td>
              <Typography level="body-md" sx={{ overflowWrap: 'anywhere' }}>
                {formatDateTime(transactionById.updatedAt)}
              </Typography>
            </td>
            <td>
              <Typography
                level="body-md"
                sx={{ color: getStatusTextColor(transactionById?.status) }}
              >
                {transactionById?.status &&
                  transactionById.status.charAt(0).toUpperCase() +
                    transactionById.status.slice(1).toLowerCase()}
              </Typography>
            </td>
            <td>
              <Button
                variant="plain"
                sx={{
                  display: 'block',
                  m: 0,
                  borderBottom: '1px solid #947EFE',
                  color: '#947EFE',
                  borderRadius: 0,
                  p: 0,
                }}
                onClick={toggleDrawer(true)}
              >
                {t('history_table_details')}
              </Button>
            </td>
          </tr>
          <HistoryModalQAC
            id={transactionById.id}
            open={open}
            onClose={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          />
        </tbody>
      </Table>
    </>
  );
};

export default QacTransactionById;
