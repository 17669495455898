import { Table, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { NotificationLog } from '../../utils/types';
import NotLogItem from './NotLogItem';
import { useTranslation } from 'react-i18next';

const NotLog = ({ notifications }: { notifications: NotificationLog[] }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<readonly number[]>([]);

  return (
    <>
      <Table
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',

        }}
      >
        <thead >
          <tr>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_card')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('header')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_search_bank')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('matched')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_Date')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_actions')}</th>
          </tr>
        </thead>
        <tbody>
          {notifications &&
            notifications?.map((not: NotificationLog) => (
              <NotLogItem
                key={not.id}
                notification={not}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
        </tbody>
      </Table>
      {!notifications && <Typography sx={{ mt: 2, pl: 2, }}>{t('not_found')}</Typography>}
    </>
  );
};
export default NotLog;
