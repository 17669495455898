import { Typography, Button } from '@mui/joy';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { formatDateTime } from '../../utils/dateFormater';
import { Payout } from '../../utils/types';
import { useTranslation } from 'react-i18next';
import { getAllPayouts, processPayoutStatus, updatePayout } from '../../services/operators';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import { initialFilterState } from '../../pages/History/History';
import { getActivePayouts, getOperatorById } from '../../redux/auth/operations';
import { toast } from 'react-toastify';
import { AppDispatch } from '../../redux/store';
import ModalConfirm from '../../pages/ActivePayouts/ModalConfirm';
import { maskCreditCardNumber } from '../../utils/formatCreditCard';

const PendingPayoutItem = ({
  payout,
}: {
  payout: Payout;
  selected: readonly number[];
  setSelected: Dispatch<SetStateAction<readonly number[]>>;
}) => {
  const { t } = useTranslation();
  const { user } = useSelector(getGlobalUser);
  const [acceptTrans, setAcceptTrans] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [receiptNumber, setReceiptNumber] = useState('');
  const [receiptUrl, setReceiptUrl] = useState('');
  const [image, setImage] = useState<File[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  const firstSixDigits = payout.pocket_address.slice(0, 4);
  const lastFourDigits = payout.pocket_address.slice(-3);
  const maskedPart = '*'.repeat(9);
  const cardNumber = `${firstSixDigits}${maskedPart}${lastFourDigits}`;

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setImage(filesArray);
    }
  };

  const handleAcceptPending = async () => {
    try {
      await updatePayout(payout.id, payout?.amount, receiptNumber, receiptUrl, image ?? null);
      const fetch = async () => {
        const data = await getAllPayouts(user.id as number, null, null, {
          ...initialFilterState,
          searchStatus: 'PROCESSING',
        });
        dispatch(getActivePayouts(data.payouts));
      };

      fetch();
      dispatch(getOperatorById(user.id));
      setAcceptTrans(true);
    } catch {
      toast.error(t('toast_AcceptPayout'));
    }
  };

  const toggleOpenModal = async () => {
    if (payout.status === 'PENDING' && !openModalConfirm) {
      const result = await processPayoutStatus(payout.id);
      if (result === false) {
        return;
      }
    }
    if (openModalConfirm) {
      const data = await getAllPayouts(user.id as number, null, null, {
        ...initialFilterState,
        searchStatus: 'PROCESSING',
      });
      dispatch(getActivePayouts(data.payouts));
    }
    setOpenModalConfirm(!openModalConfirm);
  };

  const handleChange = (value: string) => {
    setReceiptNumber(value);
  };

  const handleChangeReceiptUrl = (value: string) => {
    setReceiptUrl(value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    toggleOpenModal();
    handleAcceptPending();
  };

  let type = '';
  let card = '';
  let iban = '';
  const pocketAddress = payout.pocket_address.replace(/\s/g, '');

  if (pocketAddress.length > 34 && pocketAddress.includes(';')) {
    type = 'both';
    const separateArray = pocketAddress.split(';').map((item) => item.trim());
    card = separateArray[0];
    iban = separateArray[1];
  } else if (pocketAddress.length <= 19 && pocketAddress.length >= 16 && !pocketAddress.includes('UA')) {
    type = 'card';
  } else if (pocketAddress.includes('UA') && pocketAddress.length === 29) {
    type = 'iban'
  }

  return (
    <>
      <tr>
        <td>
          {type !== 'both' ? (
          <Typography level="body-md" sx={{ color: '#CED4DA', overflowWrap: 'anywhere' }}>
            {cardNumber}
          </Typography>
          ) : (
            <Typography level="body-md" sx={{ color: '#CED4DA', overflowWrap: 'anywhere' }}>
              {maskCreditCardNumber(card)}
              <br /> {/* Self-closing */}
              {(iban)}
            </Typography>
          )}
        </td>
        <td>
          <Typography level="body-md" sx={{ color: '#F1F1F1', fontWeight: '14px' }}>
            {formatDateTime(payout.created_at.toString())}
          </Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{ fontWeight: 500, color: '#F1F1F1' }}>
            *****
          </Typography>
        </td>
        <td>
          <Button
            variant="plain"
            onClick={toggleOpenModal}
            disabled={acceptTrans}
            sx={{
              display: 'block',
              color: '#947EFE',
              fontWeight: 500,
              borderBottom: '1px solid #947EFE',
              p: 0,
              borderRadius: 0,
              m: 0,
            }}
          >
            {t('pending_payout_proceed')}
          </Button>
        </td>
      </tr>
      <ModalConfirm
        openModal={openModalConfirm}
        toggleOpenModal={toggleOpenModal}
        onSubmit={onSubmit}
        handleChange={handleChange}
        payout={payout}
        handleChangeReceiptUrl={handleChangeReceiptUrl}
        handleChangeImage={handleChangeImage}
      />
    </>
  );
};

export default PendingPayoutItem;
