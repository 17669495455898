import { Box, Divider, Drawer, Option, Select, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changeAcqTransactionStatus, getQacTransactioById } from '../../services/operators';
import { formatDateTime } from '../../utils/dateFormater';
import { getStatusTextColor } from '../../utils/getStatusColor';
import { Transactions } from '../../utils/types';
import { LuCopy, LuCopyCheck } from 'react-icons/lu';
import CopyToClipboard from 'react-copy-to-clipboard';

const HistoryModalQAC = ({
    id,
    open,
    onClose,
    onKeyDown,
}: {
    id: number;
    open: boolean;
    onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
    onKeyDown: (event: React.KeyboardEvent | React.MouseEvent) => void;
}) => {
    const [transaction, setTransaction] = useState<Transactions | null>(null);
    const { t } = useTranslation();
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            const data = await getQacTransactioById(id);
            if (data) {
                setTransaction(data);
            }
        };
        if (open) {
            fetch();
        }
    }, [open]);
    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };

    const formatAmountNumber = (number: string): string => {
        const formattedNumber = number.replace(',', '.'); // Ensure correct decimal separator
        const [integerPart, decimalPart] = formattedNumber.split('.') as [string, string | undefined];
        const spacedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return decimalPart !== undefined ? `${spacedIntegerPart}.${decimalPart}` : spacedIntegerPart;
    };

    const handleChangeStatus = async (_event: React.SyntheticEvent | null, newValue: string | null) => {
        if (transaction !== null && newValue !== null) {
            console.log('asduashduhsd');
            await changeAcqTransactionStatus(transaction?.id, newValue)
        }
    };

    return (
        <Drawer anchor={'right'} open={open} onClose={onClose}>
            <Box role="presentation" onKeyDown={onKeyDown}>
                <Box sx={{ p: 3 }}>
                    <Typography sx={{ mb: 3 }} level="h4">
                        {t('details_trans_header')}
                    </Typography>
                    {!transaction && (
                        <Typography sx={{ mb: 3 }} level="body-md">
                            {t('not_found')}
                        </Typography>
                    )}
                    {transaction && (
                        <>
                            <Box>
                                <Typography level="title-lg" sx={{ color: '#947EFE', mb: 2 }}>
                                    {t('details_trans_name')}
                                </Typography>
                                <Typography level="body-md">{`ID: ${transaction.id}`}</Typography>
                                <Box sx={{ display: 'flex'}}>
                                    <Typography level="body-md" sx={{ overflowWrap: 'anywhere' }}>{`ID (hash): ${transaction.encrypted_id}`}</Typography>
                                    <CopyToClipboard text={transaction.encrypted_id} onCopy={onCopyText}>
                                        <span style={{
                                            padding: '4px',
                                            borderRadius: '4px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            color: '#fff',
                                            transition: 'background-color 0.3s ease',
                                        }}>
                                            {(isCopied && <LuCopyCheck size={17} />) || <LuCopy size={17} />}
                                        </span>
                                    </CopyToClipboard>
                                </Box>
                                <Typography level="body-md">
                                    {`Order id: ${transaction.order_id}`}
                                </Typography>
                                <Typography level="body-md" sx={{ overflowWrap: 'anywhere' }}>
                                    {`Customer id: ${transaction.customer_id}`}
                                </Typography>
                                <Typography level="body-md">
                                    {`${t('history_table_startDate')}: ${formatDateTime(transaction.createdAt)}`}
                                </Typography>
                                <Typography level="body-md">
                                    {`${t('history_table_endDate')}: ${formatDateTime(transaction.updatedAt)}`}
                                </Typography>
                                <Typography level="body-md">
                                    {`${t('history_table_course')}: ${Number(transaction.currentCourse).toFixed(2)}`}
                                </Typography>
                                <Typography level="body-md">
                                    {`${t('history_table_priceUah', { currency: (localStorage.getItem('base_currency')) || '', })}: ${formatAmountNumber(Number(transaction.amount).toFixed(2).toString())}`}
                                </Typography>
                                <Typography level="body-md">
                                    {`${t('history_table_priceUsdt')}: ${formatAmountNumber(Number(transaction.operatorAmount
                                    ).toFixed(2).toString())}`}
                                </Typography>
                                <Box
                                  sx={{
                                      display: 'flex',
                                      gap: '10px',
                                      alignItems: 'center',
                                      mb: 2,
                                  }}
                                >
                                    <Typography level="body-md">{t('history_table_status') + ':'}</Typography>
                                    {transaction.status === 'EXPIRED' || transaction.status === 'DISPUTE' ? (
                                      <>
                                          <Typography
                                            level="body-md"
                                            sx={{ color: getStatusTextColor(transaction.status) }}
                                          >
                                              {transaction.status}
                                          </Typography>
                                          <Select
                                            placeholder={transaction.status}
                                            variant="outlined"
                                            slotProps={{
                                                listbox: {
                                                    sx: {
                                                        '--List-padding': '0px',
                                                        '--ListItem-radius': '0px',
                                                    },
                                                },
                                            }}
                                            onChange={(_event: React.SyntheticEvent | null, newValue: string | null) => {
                                                handleChangeStatus(_event, newValue);
                                            }}
                                          >
                                              <Option
                                                value={'PAID'}
                                                sx={{
                                                    p: 0,
                                                }}
                                              >
                                                  PAID
                                              </Option>
                                          </Select>
                                          <Typography
                                            level="body-md"
                                            sx={{ color: getStatusTextColor(transaction.status) }}
                                          >
                                          </Typography>
                                      </>
                                    ) : (
                                      <Typography
                                        level="body-md"
                                        sx={{ color: getStatusTextColor(transaction.status) }}
                                      >
                                          {transaction.status}
                                      </Typography>
                                    )}
                                </Box>
                                <Divider />
                                <Typography level="title-lg" sx={{
                                    color: '#947EFE', mt: 2, mb: 2 }}>
                                    {t('detailsTerminal')}
                                </Typography>
                                <Typography level="body-md" sx={{overflowWrap:'anywhere'}}>
                                    {`${t('terminalHeader')}: 

                                     ${transaction?.jar_link}
                                   `}
                                </Typography>
                                <Typography level="body-md">
                                    {`${t('history_table_name')}: ${transaction.label}`}
                                </Typography>
                                <Typography sx={{ mb: 2 }} level="body-md">
                                    {`${t('transaction_bank')}: ${transaction.operator_bank_title}`}
                                </Typography>
                                <Divider />
                                <Typography level="title-lg" sx={{ color: '#947EFE', mt: 2, mb: 2 }}>
                                    {t('details_trans_operator')}
                                </Typography>
                                <Typography level="body-md">{`ID: ${transaction.operator?.user.id}`}</Typography>
                                <Typography level="body-md">
                                    {`${t('settings_checkbox_email')}: ${transaction.operator?.user.email}`}
                                </Typography>
                                <Typography level="body-md">
                                    {`${t('details_name')}: ${transaction.operator?.user.name}`}
                                </Typography>
                                <Typography level="body-md">
                                    {`${t('details_trans_tax')}: ${Number(transaction.operators_fee
                                    ).toFixed(2)} %`}
                                </Typography>
                                <Typography sx={{ mb: 2 }} level="body-md">
                                    {`${t('details_trans_profit')} ${Number(transaction.operator_reward).toFixed(2)}`}
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Drawer>
    );
};

export default HistoryModalQAC;
