import { Button, Typography } from '@mui/joy';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { maskCreditCardNumber } from '../../utils/formatCreditCard';
import { NotificationLog } from '../../utils/types';
import NotLogItemModal from './NotLogItemModal';
import { formatDateTime } from '../../utils/dateFormater';

const NotLogItem = ({
  notification,
}:
  {
    notification: NotificationLog;
    selected: readonly number[];
    setSelected: Dispatch<SetStateAction<readonly number[]>>;
  }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(inOpen);
  };
  return (
    <>
      <tr key={notification.id}>
        <td>
          <Typography level="body-xs">
            {maskCreditCardNumber(notification.card)}
          </Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{ overflowWrap: 'anywhere' }}>
            {notification.header}
          </Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{ overflowWrap: 'anywhere' }}>
            {notification.app}
          </Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{ overflowWrap: 'anywhere' }}>
            {notification.matched ? '+' : '-'}
          </Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{ overflowWrap: 'anywhere' }}>{(formatDateTime(notification.received_at.toString()))}</Typography>
        </td>
        <td>
          <Button
            variant="plain"
            sx={{
              display: 'block',
              m: 0,
              borderBottom: '1px solid #947EFE',
              color: '#947EFE',
              borderRadius: 0,
              p: 0,
            }}
            onClick={toggleDrawer(true)}
          >
            {t('history_table_details')}
          </Button>
        </td>
      </tr>

      <NotLogItemModal
        id={notification.id}
        open={open}
        onClose={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      />
    </>
  );
};
export default NotLogItem;
