import {
  Box,
  IconButton,
  List,
  ListDivider,
  ListItem,
  ListItemContent,
  Switch,
  Typography,
  switchClasses,
  Theme,
  Modal,
  Sheet,
  Button
} from '@mui/joy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillEdit } from 'react-icons/ai';
import { BiSolidArchiveIn } from 'react-icons/bi';
import { Terminal, Banks } from '../../../utils/types';
import DeleteCardModal from '../../CardList/DeleteCardModal';
import EditJarModal from '../EditJarModal';
import { BiSolidArchiveOut } from "react-icons/bi";

const CardItemMobile = ({
  listItem,
  handleUpdate,
  banks,
  handleEditCard,
  handleDelete,
}: {
  listItem: Terminal;
  banks: Banks[];

  handleUpdate: ({
    id,
    accessibility,
  }: {
    id: number;
    accessibility: boolean;
  }) => void;
  handleEditCard: (
    jars: {
      id: number;
      label: string;
      dailyLimit: number;
      monthly_limit: number;
      is_active: boolean;
      bank_id: number;
      daily_transaction_limit: number;
    }[]
  ) => void;
  handleDelete: (id: number) => void;
}) => {
  const [accessibility, setAccessibility] = useState(listItem.is_active);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [restoreOpen, setRestoreOpen] = useState(false);
  const handleDeleteCard = async () => {
    handleDelete(listItem.id);
    setDeleteOpen(!deleteOpen);
  };
  const handleRestore = async () => {
    handleDelete(listItem.id);
    setRestoreOpen(!restoreOpen);

  }
  const handleChangeCheckbox = async () => {
    handleUpdate({
      id: listItem.id,
      accessibility: !accessibility,
    });
    setAccessibility(!accessibility);
  };
  const formatAmountNumber = (number: string): string => {
    const formattedNumber = number.replace(',', '.'); // Ensure correct decimal separator
    const [integerPart, decimalPart] = formattedNumber.split('.') as [string, string | undefined];
    const spacedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return decimalPart !== undefined ? `${spacedIntegerPart}.${decimalPart}` : spacedIntegerPart;
  };

  const { t } = useTranslation();
  return (
    <>
      <List
        size="sm"
        sx={{
          position: 'relative',
          '--ListItem-paddingX': 0,
        }}
      >
        <ListItem
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <ListItemContent>
            <Box>
              <Typography level="body-sm" sx={{ color: "#f1f1f1"}}>
                {listItem.jar_link}
              </Typography>
              <Typography level="body-sm" sx={{ fontWeight: 700, color:'#947EFE' }}>
                {listItem.label}
              </Typography>
            </Box>
            <Typography level="body-sm" sx={{ mt: 2 }}>
              {t('card_table_turnover')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography level="body-sm">
                {t('card_table_turnoverDay', {
                  daily: (formatAmountNumber(listItem.daily_balance.toString()) && formatAmountNumber(listItem.daily_balance.toString())) || 0,
                  currency: (localStorage.getItem('base_currency')) || '',
                })}
              </Typography>

              <Typography level="body-sm">
                {t('card_table_turnoverMonth', {
                  monthly: (formatAmountNumber(listItem.month_balance.toString()) && formatAmountNumber(listItem.month_balance.toString())) || 0,
                  currency: (localStorage.getItem('base_currency')) || 0,
                })}
              </Typography>
            </Box>

            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography level="body-sm">{t('card_table_dayLimit')}</Typography>
              <Typography level="body-sm" sx={{ fontWeight: 700 }}>
                {`${listItem.daily_limit ? formatAmountNumber(listItem.daily_limit.toString()) : ''} ${localStorage.getItem('base_currency')}`}
              </Typography>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography level="body-sm">{t('card_table_monthLimit')}</Typography>
              <Typography level="body-sm" sx={{ fontWeight: 700 }}>
                {`${listItem.month_limit ? formatAmountNumber(listItem.month_limit.toString()) : ''} ${localStorage.getItem('base_currency')}`}
              </Typography>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography level="body-sm">{t('cardTableLimit')}</Typography>
              <Typography level="body-sm" sx={{ fontWeight: 700 }}>
                {listItem.daily_transaction_done} / {listItem.daily_transaction_limit}
              </Typography>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography level="body-sm">{t('card_tableDailySF')}</Typography>
              <Typography level="body-sm" sx={{ fontWeight: 700 }}> {listItem.count_daily_success} / {listItem.count_daily_failed}</Typography>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography level="body-sm">{t('card_tableMonthSF')}</Typography>
              <Typography level="body-sm" sx={{ fontWeight: 700 }}>{listItem.count_total_success} / {listItem.count_total_failed}</Typography>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography level="body-sm">{t('card_table_bank')}</Typography>
              <Typography level="body-sm">{listItem.bank.bank.title}</Typography>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}></Box>
            {!listItem.archivated && (
              <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Typography level="body-sm">{t('card_table_accessibility')}</Typography>
                <Switch
                  size="md"
                  variant={'solid'}
                  checked={accessibility}
                  onChange={handleChangeCheckbox}
                  sx={(theme: Theme) => ({

                    '--Switch-thumbSize': '20px',
                    '--Switch-trackWidth': '46px',
                    '--Switch-trackHeight': '20px',
                    '--Switch-trackBackground': theme.vars.palette.background.level3,
                    [`& .${switchClasses.thumb}`]: {
                      transition: 'width 0.2s, left 0.2s',
                    },
                    '&:hover': {
                      '--Switch-trackBackground': theme.vars.palette.background.level3,
                    },
                    '&:active': {
                      '--Switch-thumbWidth': '32px',
                    },
                    [`&.${switchClasses.checked}`]: {
                      '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                      '&:hover': {
                        '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                      },
                    },
                  })}
                />
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: '20px',
                right: 0,
              }}
            >
              {!listItem.archivated && (
                <IconButton
                  aria-label="delete"
                  title="archive"
                  onClick={() => setDeleteOpen(!deleteOpen)}
                >
                  <BiSolidArchiveIn style={{ width: '20px', height: '20px' }} />
                </IconButton>
              )}
              {listItem.archivated && (
                <IconButton
                  aria-label="archiveOut"
                  title="archiveOut"
                  onClick={() => setRestoreOpen(!restoreOpen)}
                >
                  <BiSolidArchiveOut style={{ width: '20px', height: '20px' }} />
                </IconButton>
              )}
              {!listItem.archivated && (
                <IconButton aria-label="delete" title="edit" onClick={() => setEditOpen(!editOpen)}>
                  <AiFillEdit style={{ width: '20px', height: '20px' }} />
                </IconButton>
              )}
            </Box>
          </ListItemContent>
        </ListItem>
        <ListDivider />
        <EditJarModal
          editOpen={editOpen}
          setEditOpen={setEditOpen}
          card={listItem}
          handleEditCard={handleEditCard}
          banks={banks}
        />
        <DeleteCardModal
          deleteOpen={deleteOpen}
          setDeleteOpen={setDeleteOpen}
          handleDeleteCard={handleDeleteCard}
          textBtn={t('terminalmodalDeleteBtn')}
          title={t('terminalModalDeleteHeader')}
        />
      </List>
      {restoreOpen && (
        <Modal
          open={restoreOpen}
          onClose={() => {
            setRestoreOpen(!restoreOpen);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: '80%',
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
            }}
          >
            <Typography level="body-lg" sx={{ textAlign: 'center', mb: 2 }}>
              {t('terminalModalRestoreHeader')}
            </Typography>
            <Button
              onClick={handleRestore}
              sx={{
                display: 'block', m: '0 auto',
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                color: '#f1f1f1',
                '&:hover': {
                  background:
                    'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                },
              }}>
              {t('terminalModalRestoreBtn')}
            </Button>
          </Sheet>
        </Modal>
      )
      }
    </>
  );
};

export default CardItemMobile;
