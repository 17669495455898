import { Box } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { Transactions } from '../../../../utils/types';
import HistoryItem from './HistoryItem';

const HistoryList = ({
  pageCount,
  handlePageClick,
  list,
  onClose,
}: {
  pageCount: number;
  handlePageClick: ({ selected }: { selected: number }) => void;
  list: Transactions[];
  onClose: (inOpen: boolean) => void;
}) => {
  const [listTransactions, setListTransactions] = useState(list);

  useEffect(() => {
    setListTransactions(list);
  }, [list]);
  const { t } = useTranslation();
  return (
    <Box>
      {listTransactions?.map((listItem: Transactions) => (
        <HistoryItem listItem={listItem} key={listItem.id} onClose={onClose} />
      ))}
      {pageCount > 1 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            mt: 2,
          }}
        >
          <ReactPaginate
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel={t('pagination_prev')}
            nextLabel={t('pagination_next')}
            containerClassName={'pagination'}
            pageLinkClassName={'page-number'}
            previousLinkClassName={'page-number'}
            nextLinkClassName={'page-number'}
            activeLinkClassName={'active'}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
          />
        </Box>
      )}
    </Box>
  );
};

export default HistoryList;
