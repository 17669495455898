import {
  Box,
  IconButton,
  List,
  ListDivider,
  ListItem,
  ListItemContent,
  Switch,
  Typography,
  Modal,
  Sheet,
  Button,
  switchClasses,
} from '@mui/joy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillEdit } from 'react-icons/ai';
import { BiSolidArchiveIn } from 'react-icons/bi';
import { Banks, Cards, Tags } from '../../../utils/types';
import DeleteCardModal from '../DeleteCardModal';
import EditCardModal from '../EditCardModal';
import { BiSolidArchiveOut } from "react-icons/bi";
import { formatingCardNumber } from '../../../utils/formationCardNum';

const CardItem = ({
  listItem,
  handleUpdate,
  banks,
  tags,
  handleEditCard,
  handleDelete,
}: {
  listItem: Cards;
  banks: Banks[];
  tags: Tags[];
  handleUpdate: ({
    id,
    balance,
    accessibility,
  }: {
    id: number;
    balance: number;
    accessibility: boolean;
  }) => void;
  handleEditCard: (
    cards: {
      id: number;
      bank_id: number;
      tag: number | null;
      monthly_limit: number;
      dailyLimit: number;
      label: string;
      is_active: boolean;
      send_id: string;
      daily_transaction_limit: number;
      allow_same_amount: boolean;
    }[]
  ) => void;
  handleDelete: (id: number) => void;
}) => {
  const [accessibility, setAccessibility] = useState(listItem.is_active);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [restoreOpen, setRestoreOpen] = useState(false);

  const handleDeleteCard = async () => {
    handleDelete(listItem.id);
    setDeleteOpen(!deleteOpen);
  };

  const handleRestore = async () => {
    handleDelete(listItem.id);
    setRestoreOpen(!restoreOpen);
    
  }
  const handleChangeCheckbox = async () => {
    handleUpdate({
      id: listItem.id,
      balance: listItem.balance,
      accessibility: !accessibility,
    });
    setAccessibility(!accessibility);
  };

  const { t } = useTranslation();

  const formatAmountNumber = (number: string): string => {
    const formattedNumber = number.replace(',', '.'); // Ensure correct decimal separator
    const [integerPart, decimalPart] = formattedNumber.split('.') as [string, string | undefined];
    const spacedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return decimalPart !== undefined ? `${spacedIntegerPart}.${decimalPart}` : spacedIntegerPart;
  };

  return (
    <List
      size="sm"
      sx={{
        position: 'relative',
        '--ListItem-paddingX': 0,
      }}
    >
      <ListItem
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <ListItemContent>
          <Box>
            <Typography level="body-sm" sx={{ color: '#f1f1f1' }}>
              {formatingCardNumber(listItem.pocket_address)}
            </Typography>
            <Typography level="body-sm" sx={{ fontWeight: 700 }}>
              {listItem.label}
            </Typography>
          </Box>
          <Typography level="body-sm" sx={{ mt: 2 }}>
            {t('card_table_turnover')}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography level="body-sm">
              {t('card_table_turnoverDay', {
                daily: (listItem.daily_balance && listItem.daily_balance) || 0,
                currency: (localStorage.getItem('base_currency')) || '',
              })}
            </Typography>

            <Typography level="body-sm">
              {t('card_table_turnoverMonth', {
                monthly: (listItem.month_balance && listItem.month_balance) || 0,
                currency: (localStorage.getItem('base_currency')) || 0,
              })}
            </Typography>
          </Box>

          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-sm">{t('card_table_dayLimit')}</Typography>
            <Typography level="body-sm" sx={{ fontWeight: 700 }}>
              {`${listItem.daily_limit ? formatAmountNumber(listItem.daily_limit.toString()) : ''} ${localStorage.getItem('base_currency')}`}
            </Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-sm">{t('card_table_monthLimit')}</Typography>
            <Typography level="body-sm" sx={{ fontWeight: 700 }}>
              {`${listItem.month_limit ? formatAmountNumber(listItem.month_limit.toString()) : ''} ${localStorage.getItem('base_currency')}`}
            </Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-sm">{t('cardTableLimit')}</Typography>
            <Typography level="body-sm" sx={{ fontWeight: 700 }}>
              {formatAmountNumber(listItem.daily_transaction_done.toString())} / {formatAmountNumber(listItem.daily_transaction_limit.toString())}
            </Typography>
          </Box>

          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-sm">{t('card_table_bank')}</Typography>
            <Typography level="body-sm">{listItem.bank.bank.title}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-sm">{t('card_modal_tag')}</Typography>
            <Typography
              level="body-sm"
              sx={{
                bgcolor: (listItem.tag && 'rgba(104, 79, 221, 0.2)') || 'transparent',
                width: 'fit-content',
                p: '2px 10px',
                borderRadius: '8px',
              }}
            >
              {(listItem.tag && `#${listItem.tag.title}`)}
            </Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-sm">{t('conversion')} {listItem.conversion_time_limit} min:</Typography>
            <Typography
              level="body-sm"
              sx={{
                bgcolor: (listItem.tag && 'rgba(104, 79, 221, 0.2)') || 'transparent',
                width: 'fit-content',
                p: '2px 10px',
                borderRadius: '8px',
              }}
            >
              {listItem.conversion}%{` / Min: ${listItem.conversion_limit}%`}
            </Typography>
          </Box>
          {!listItem.archivated && (
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography level="body-sm">{t('card_table_accessibility')}</Typography>
              <Switch
                size="md"
                variant={'solid'}
                checked={accessibility}
                onChange={handleChangeCheckbox}
                sx={() => ({

                  '--Switch-thumbSize': '20px',
                  '--Switch-trackWidth': '46px',
                  '--Switch-trackHeight': '20px',
                  '--Switch-trackBackground': 'rgb(169,42,32)',
                  [`& .${switchClasses.thumb}`]: {
                    transition: 'width 0.2s, left 0.2s',
                  },
                  '&:hover': {
                    '--Switch-trackBackground': 'rgb(169,42,32)',
                  },
                  '&:active': {
                    '--Switch-thumbWidth': '32px',
                  },
                  [`&.${switchClasses.checked}`]: {
                    '--Switch-trackBackground': 'rgb(32,182,44)',
                    '&:hover': {
                      '--Switch-trackBackground': 'rgb(32,182,44)',
                    },
                  },
                })}
              />
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            {!listItem.archivated && (
              <IconButton
                aria-label="delete"
                title="archive"
                onClick={() => setDeleteOpen(!deleteOpen)}
              >
                <BiSolidArchiveIn style={{ width: '20px', height: '20px' }} />
              </IconButton>
            )}
            {listItem.archivated && (
              <IconButton
                aria-label="archiveOut"
                title="archiveOut"
                onClick={() => setRestoreOpen(!restoreOpen)}
              >
                <BiSolidArchiveOut style={{ width: '20px', height: '20px' }} />
              </IconButton>
            )}

            {!listItem.archivated && (
              <IconButton aria-label="delete" title="edit" onClick={() => setEditOpen(!editOpen)}>
                <AiFillEdit style={{ width: '20px', height: '20px' }} />
              </IconButton>
            )}
          </Box>
        </ListItemContent>
      </ListItem>
      <ListDivider />
      <EditCardModal
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        card={listItem}
        handleEditCard={handleEditCard}
        banks={banks}
        tagList={tags}
      />
      <DeleteCardModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        handleDeleteCard={handleDeleteCard}
        textBtn={t('card_modal_deleteBtn')}
        title={t('card_modal_deleteHeader')} 
      />
      {restoreOpen && (
        <Modal
          open={restoreOpen}
          onClose={() => {
            setRestoreOpen(!restoreOpen);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: '80%',
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
            }}
          >
            <Typography level="body-lg" sx={{ textAlign: 'center', mb: 2 }}>
              {t('cardModalRestoreHeader')}
            </Typography>
            <Button
              type="button"
              onClick={handleRestore}
              sx={{
                display: 'block', m: '0 auto',
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                '&:hover': {
                  background:
                    'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                },
              }}
            >
              {t('cardModalRestoreBtn')}
            </Button>
          </Sheet>
        </Modal>
      )}
    </List>
  );
};

export default CardItem;
