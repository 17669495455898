import { Box, Drawer, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getNotificationLogById } from '../../services/operators';
import { formatDateTime } from '../../utils/dateFormater';
import { NotificationLog } from '../../utils/types';

const HistoryItemModal = ({
  id,
  open,
  onClose,
  onKeyDown,
}: {
  id: number;
  open: boolean;
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
  onKeyDown: (event: React.KeyboardEvent | React.MouseEvent) => void;
}) => {
  const [notLog, setNotLog] = useState<NotificationLog | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      const data = await getNotificationLogById(id);

      if (data && data.notifications) {
        setNotLog(data.notifications[0]);
      }
    };
    if (open) {
      fetch();
    }
  }, [open]);

  return (
    <Drawer anchor={'right'} open={open} onClose={onClose}>
      <Box role="presentation" onKeyDown={onKeyDown}>
        <Box sx={{ p: 3 }}>
          <Typography sx={{ mb: 3 }} level="h4">
            {t('sidebar_nav_notification_log')}
          </Typography>
          {notLog && (
            <>
              <Box>
                <Typography level="body-md" sx={{overflowWrap: 'anywhere'}}>ID: {`${notLog.id}`}</Typography>
                <Typography level="body-md">{t('history_table_card')}: {notLog.card}</Typography>
                <Typography level="body-md">{t('card_search_bank')}: {notLog.app}</Typography>
                <Typography level="body-md">{t('header')}: {notLog.header}</Typography>
                <Typography level="body-md">{t('text')}: {notLog.text}</Typography>
                <Typography level="body-md">{t('notification')}: {notLog.notification}</Typography>
                <Typography level="body-md">{t('matched')}: {notLog.matched ? '+' : '-'}</Typography>
                <Typography level="body-md">
                  {`${t('received_at')}: ${formatDateTime(notLog.received_at.toString())}`}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default HistoryItemModal;
