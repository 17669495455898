import React, { useState } from "react";
import { TabPanel, Tabs, TabList, Tab } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import ActiveDisputesP2P from './ActiveDisputesP2P';
import ActiveDisputesQAC from './ActiveDisputesQAC';

const ActiveDisputes = () => {
  const [activeTab, setActiveTab] = useState('0');
  const { t } = useTranslation();

  const handleTabChange = (newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <Tabs value={activeTab}
      onChange={(_event, value) => handleTabChange(value ? value.toString() : '0')}
    >
      <TabList>
        <Tab value="0">{t('sidebar_nav_dispute_active_p2p')}</Tab>
        <Tab value="1">{t('sidebar_nav_dispute_active_acq')}</Tab>
      </TabList>

      <TabPanel value="0">
        <ActiveDisputesP2P/>
      </TabPanel>

      <TabPanel value="1">
        <ActiveDisputesQAC/>
      </TabPanel>
    </Tabs>
  );
};

export default ActiveDisputes;
