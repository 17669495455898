import { Button, Table, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { NotificationLog } from '../../utils/types';
import { useTranslation } from 'react-i18next';
import NotLogItemModal from './NotLogItemModal';
import { formatDateTime } from '../../utils/dateFormater';

const NotLogById = ({ notLogById }: { notLogById: NotificationLog }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setOpen(inOpen);
    };
    return (
        <>
            <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',
                }}
            >
                <thead>
                <tr>
                    <th style={{ color: '#F1F1F1' }}>{t('bank')}</th>
                    <th style={{ color: '#F1F1F1' }}>{t('text')}</th>
                    <th style={{ color: '#F1F1F1' }}>{t('header')}</th>
                    <th style={{ color: '#F1F1F1' }}>{t('card')}</th>
                    <th style={{ color: '#F1F1F1' }}>{t('notification')}</th>
                    <th style={{ color: '#F1F1F1' }}>{t('matched')}</th>
                    <th style={{ color: '#F1F1F1' }}>{t('received_at')}</th>
                </tr>
                </thead>
                <tbody>
                <tr key={notLogById.id}>
                    <td>
                        <Typography level="body-xs">
                            {notLogById.app}
                        </Typography>
                    </td>
                    <td>
                        <Typography level="body-xs">
                          {notLogById.text}
                        </Typography>
                    </td>
                    <td>
                        <Typography level="body-xs">
                          {notLogById.header}
                        </Typography>
                    </td>
                    <td>
                        <Typography level="body-md">
                          {notLogById.card}
                        </Typography>
                    </td>
                    <td>
                        <Typography level="body-md">
                          {notLogById.notification}
                        </Typography>
                    </td>
                    <td>
                        <Typography level="body-md">
                          {notLogById.matched}
                        </Typography>
                    </td>
                    <td>
                        <Typography level="body-md">{formatDateTime(notLogById.received_at.toString())}</Typography>
                    </td>

                    <td>
                        <Button
                          variant="plain"
                          sx={{
                              display: 'block',
                              m: 0,
                              borderBottom: '1px solid #947EFE',
                              color: '#947EFE',
                              borderRadius: 0,
                              p: 0,
                          }}
                          onClick={toggleDrawer(true)}
                        >
                            {t('history_table_details')}
                        </Button>
                    </td>
                </tr>
                <NotLogItemModal
                  id={notLogById.id}
                  open={open}
                  onClose={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                />

                </tbody>
            </Table>
        </>
    );
};

export default NotLogById;